import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NtfList from './NtfList'
import mask from '../../../assets/images/mask.jpg';
import SelectedImageHolder from './SelectedImageHolder';
import { zoomFetchTokenUris } from '../../../utils/zoom2'
import { useZoom2Contract } from '../../../hooks/useContract'
import { Contract, ethers } from 'ethers';
import useWeb3Ctx from '../../../hooks/useWeb3Ctx';
import config from '../../../config/config';
import ABI from '../../../utils/abi/Token.json'
import { getTokenUri } from '../../../utils';
import LoadingSpinner from '../../../components/LoadingSpinner';
import abi from '../../../contracts/SaleContract.json';
import { gameUrl, updateToken } from '../../../Api';
import { toast } from 'react-toast';
import PendingBurn from './PendingBurn'
import FinnishTab from './FinnishTab';

const BP1 = '@media (max-width: 1100px)';

const METADATA = [
    {
        id: 1,
        image: mask
    },
    {
        id: 1,
        image: mask
    },
    {
        id: 1,
        image: mask
    }
]
const sx = {
    root: {
        position: 'relative',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    contentHolder: {
        width: '90vw',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '25px',
        [BP1]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

        }
    },
    button: {
        mt: '64px'
    }
}

const BurnSection = ({ setStatusText }) => {
    const { onboard, handleConnect, address, ethersProvider } = useWeb3Ctx();
    const [selectedId, setSelectedId] = useState(undefined);
    const [worldMetas, setWorldMetas] = useState([]);
    const [maskedMetas, setMaskedMetas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState('Burn')
    const zoomContract = useZoom2Contract();
    const tokenAddress = config.TOKEN_CONTRACT;
    const selectMeta = (metadata, type) => {
        if (type === 'Mask') {
            setButtonText('Burn')
        } else if (type === 'World') {
            setButtonText('Reveal');
        }
        setselectedNft(metadata)
        setStatusText("NFT SELECTED")
    }
    const startNewSelection = (metadata, type) => {
        if (type === 'Mask') {
            setButtonText('Burn')
        } else if (type === 'World') {
            setButtonText('Reveal');
        }
        setSelectedId(metadata.tokenId);
        setselectedNft(metadata)
        setStatusText("NFT SELECTED")
        setActiveTab(0);
    }
    const [activeTab, setActiveTab] = useState(0);
    const [burnPending, setburnPending] = useState(false);

    const [selectedNft, setselectedNft] = useState(undefined);
    const [newMetadata, setNewMetadata] = useState(undefined);
    const saleContract = new ethers.Contract(
        '0x6DbD13D198944Bc49B983E146a9dF6bfA871CA13',
        abi.abi,
        ethersProvider
    );
    useEffect(() => {
        setActiveTab(0);
        setselectedNft(undefined);
        setNewMetadata(undefined);
    }, [address])

    const signMessage = async (message) => {
        try {
            console.log(message)
            if (!window.ethereum) {
                throw new Error('No crypto wallet found');
            }
            window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(accounts => {
                    console.log(accounts);
                });
            const signer = ethersProvider.getSigner();
            const signature = await signer.signMessage(message)
            const address = await signer.getAddress();

            return {
                message, signature, address
            }
        } catch (error) {
            console.log(error)
            toast.error(error.message)
        }
    }
    const handleSubmit = (selectedNft) => {
        setburnPending(true)
        console.log(selectedNft);
        // let dna = selectedNft;
        //SIGNATURE
        signMessage(`{tokenID: ${selectedNft.tokenId}}`).then((response) => {
            if (response) {
                setActiveTab(1);
                console.log(response);
                const obj = {
                    "address": response.address,
                    "message": `{tokenID: ${selectedNft.tokenId}}`,
                    "signature": response.signature
                }
                console.log(obj);
                if (selectedNft.attributes[0].trait_type === 'Mask') {
                    updateToken(obj).then((response) => {
                        if (response.message === 'Success') {
                            setNewMetadata(response.updatedMetadata);
                            setburnPending(false)
                            let tmpMaskArray = maskedMetas;
                            const filteredMaskArray = tmpMaskArray.filter((item) => item.tokenId !== selectedNft.tokenId);
                            setMaskedMetas(filteredMaskArray);
                            setWorldMetas((prevState) => [...prevState, response.updatedMetadata])
                        }
                        else {
                            setNewMetadata(undefined)
                            setActiveTab(1);
                        }
                    }).catch((e) => {
                        toast.error(e.message)
                        console.log(e)
                    });
                } else if (selectedNft.attributes[0].trait_type === 'World') {
                    gameUrl(obj).then((response) => {
                        console.log(response)
                        if (response.uniqueGameUrl) {
                            setNewMetadata({
                                ...selectedNft,
                                uniqueGameUrl: response.uniqueGameUrl
                            });
                            setburnPending(false)
                        }
                        else {
                            setNewMetadata(undefined)
                            setActiveTab(1);
                        }
                    }).catch((e) => {
                        toast.error(e.message)
                        console.log(e)
                    });
                }
            }
        }).catch((error) => {
            console.log(error);
            toast.error(error.message)
        })
    }
    useEffect(() => {
        if (!burnPending && newMetadata !== undefined) {
            setStatusText('CONGRATS')
            setActiveTab(2);
        }
    }, [burnPending, newMetadata])
    useEffect(() => {
        if (address) {
            setLoading(true)
            let token;
            if (config.TOKEN_CONTRACT) {
                token = new Contract(config.TOKEN_CONTRACT, ABI.abi, ethersProvider);


                if (!token) {
                    console.error('Token contract not found on address', tokenAddress);
                    return;
                }
                getTokens(token);
            }

        }
    }, [address]);
    const getTokens = async (token) => {
        setLoading(true);

        const metas = await zoomFetchTokenUris(
            token,
            zoomContract,
            address
        );
        console.log(metas)

        if (metas) {
            // console.log('TOKE META',metas);
            let maskedArray = [];
            let worldArray = [];
            metas.map((item) => {
                if (item.attributes[0].trait_type === 'Mask')
                    maskedArray.push(item);
                else if (item.attributes[0].trait_type === 'World') {
                    worldArray.push(item)
                }
            })
            setMaskedMetas(maskedArray);
            setWorldMetas(worldArray);
            setStatusText('NTF DETECTED')
        } else {
            setMaskedMetas([]);
            setWorldMetas([]);
            setStatusText('')
        }
        setLoading(false);
    };
    console.log(newMetadata)

    const handleOpenseaNavgiation = () => {
        window.open('https://opensea.io/collection/betwixt-braves?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Version&search[stringTraits][0][values][0]=Masked&search[toggles][0]=BUY_NOW', '_blank')
    }
    return (
        <Box sx={sx.root}>
            {activeTab === 0 &&
                <>
                    {loading ?
                        <LoadingSpinner />
                        :
                        worldMetas.length > 0 || maskedMetas.length > 0 ?
                            <Box sx={sx.contentHolder}>
                                <NtfList metadatas={maskedMetas} selectMeta={selectMeta} type="Mask" title={"Mask"} selectedId={selectedId} setSelectedId={setSelectedId} />
                                <SelectedImageHolder selectedNft={selectedNft} handleBurn={handleSubmit} buttonText={buttonText} />
                                <NtfList metadatas={worldMetas} selectMeta={selectMeta} type="World" title={"World"} selectedId={selectedId} setSelectedId={setSelectedId} />
                            </Box>
                            :
                            <>
                                <Typography variant='boxText'>Unfortunately you do not have any NFTs to burn!</Typography>
                                <Button sx={sx.button} variant='grayButton' onClick={handleOpenseaNavgiation}>BUY ON OPENSEA</Button>
                            </>
                    }
                </>
            }
            {activeTab === 1 && burnPending && <PendingBurn />}
            {activeTab === 2 && newMetadata && < FinnishTab maskedMetas={maskedMetas} worldMetas={worldMetas} metadata={newMetadata} startNewSelection={startNewSelection} />}
        </Box>
    )
}

export default BurnSection