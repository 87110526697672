import React from 'react'
import './Spinner.css'

const LoadingSpinner = () => {
    return (
        <div className="counter-circle" style={{ margin: "0 auto", marginBottom: "10px" }}>
            <div className="counter-rotary-holder" id='txRotator'>
                <div className="counter-rotary-item" />
            </div>
            <div className="counter-shadow">
                <div className="counter-content-holder">
                    <div className="counter-text">LOADING</div>
                </div>
            </div>

        </div>
    )
}

export default LoadingSpinner