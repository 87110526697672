import { Box } from '@mui/system'
import React from 'react'
import Typography from '@mui/material/Typography'
import GalaxisLogo from '../assets/images/logos/Galaxis.svg'
import CamelCodingLogo from '../assets/images/logos/CC.svg'

const BP1 = '@media (max-width: 600px)';
const sx = {
    root: {
        position: 'relative',
        bottom: '0',
        left: 0,
        right: 0,
        margin: '0 auto 0 auto',
        p: '35px 10px 25px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '80px',
        rowGap: '15px',
        [BP1]: {
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '40px'
        }
    },
    contentHolder: {
        display: 'flex',
        gap: '5px',
        flexDirection: 'row',
        alignItems: 'center',
        '& img': {
            maxHeight: '32px',
            height: '100%',
            cursor: 'pointer',
            [BP1]:{
                maxHeight: '26px',
              
            }
        },
        [BP1]:{
            flexDirection: 'column'
        }
    },
    text: {
        fontFamily: 'Jotia-Regular',
        textTransform: 'uppercase',
        color: '#ffffff99',
        fontSize: '12px',
        width: 'fit-content',
        [BP1]: {
            fontSize: '10px'
        }
    }
}
const Footer = () => {

    const openCC = () => {
        window.open('https://camelcoding.com/', '_blank')
    }
    const openGalaxisWebsite = () => {
        window.open('https://galaxis.xyz', '_blank')
    }

    return (
        <Box sx={sx.root}>
            <Box sx={sx.contentHolder}>
                <Typography sx={sx.text}>powered by:</Typography>
                <img src={CamelCodingLogo} alt="CAMELCODING" onClick={openCC}/>
            </Box>

            <Box sx={sx.contentHolder}>
                <Typography sx={sx.text}>blockchain platform by:</Typography>
                <img src={GalaxisLogo} alt="GALAXIS" onClick={openGalaxisWebsite}/>
            </Box>
            
        </Box>
    )
}

export default Footer