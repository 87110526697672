import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import useWeb3Ctx from '../../hooks/useWeb3Ctx';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BurnSection from './components/BurnSection'
const BP1 = '@media (max-width: 450px)';
const BP2 = '@media (max-width: 600px)';
const sx = {
    root: {
        position: 'relative',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    headerHolder: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
    },
    title: {
        mb: '20px',
        [BP1]: {
            mt: '0px !important'
        }
    },
    subTitle: {
        marginBottom: '100px',
    },
    bannerMintedPage: {
        mt: '-45px',
        [BP1]: {
            mt: 0
        }
    },
    button: {
    },
    counterHolder: {
        position: 'relative',
        width: '100%',
        minHeight: 'calc(100vh - 300px)',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        gap: '64px',
    },
    pending: {
        margin: '0 auto'
    },
    text: {
        fontSize: '18px',
        [BP2]:{
            fontSize: '18px',
            lineHeight: '30px',
        }
    }
};
const DATE = new Date('2022-10-05T15:00:00.000Z');
const Burn = () => {
    const { onboard, handleConnect, address, ethersProvider } = useWeb3Ctx();
    const [statusText, setStatusText] = useState(undefined);
    
    return (
        <Box sx={sx.root} >
            <Header statusText={statusText} />
            {!address ?
                <>
                    <Box sx={sx.counterHolder}>
                        <Typography variant="pageTitleDescription" sx={sx.text}>
                            BURN YOUR MASK TO EXPERIENCE THE BETWIXT GAME
                        </Typography>
                        {/* <Counter date={DATE} /> */}

                        <Button variant='grayButton' onClick={handleConnect} sx={sx.button}>Connect Wallet</Button>
                    </Box>
                </>
        : 
        <BurnSection setStatusText={setStatusText}/>    
        
        }

            <Footer />
        </Box>
    )
}

export default Burn