import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
const BP1 = '@media (max-width: 1100px)';
const BP2 = '@media (max-width: 450px)'
const sx = {
    root: {
        // position: 'absolute',
        // right: '60%',
        order: '1',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        gap: '25px',
        maxWidth: '315px',
        minWidth: '315px',
        maxHeight: '400px',
        overflow: 'auto',
        p: '2px',
        mt: '5px',
        [BP1]: {
            order: '1',
            maxHeight: '183px',
        }
    },
    imgHolder: {
        height: 'auto',
        cursor: 'pointer',
        width: '80px',
        height: '80px',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    placeHolder: {
        width: '78px',
        height: '78px',
        border: '1px solid #628BA0',
        backgroundColor: 'transparent',
    },
    selected: {
        width: '80px',
        height: '80px',
        '& img': {
            width: '100%',
            height: '100%',
            outline: '1px solid #FFF',
        }
    },
    simpleHolder: {
        mt: '-25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [BP1]: {
            mt: '0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    title: {
        color: '#FFFFFF99',
        [BP1]: {
            textAlign: 'center',
        }
    },
    sorryText: {
        maxWidth: '315px',
        textAlign: 'start',
        mt: '5px',
        '& span': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        [BP2]:{
            textAlign: 'center'
        }
    }
};

const NtfList = ({ metadatas, selectMeta, type, title, selectedId, setSelectedId, startNewSelection }) => {
    const handleOpenOpensea = () => {
        if (type === 'Mask') {
            window.open('https://opensea.io/collection/betwixt-braves?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Version&search[stringTraits][0][values][0]=Masked', '_blank')
        } else {
            window.open('https://opensea.io/collection/betwixt-braves?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Version&search[stringTraits][0][values][0]=Ascended', '_blank')
        }
    }
    const handleClick = (metadata, type) => {
        if (startNewSelection) {
            startNewSelection(metadata, type)
        } else {
            selectMeta(metadata, type);
            setSelectedId(metadata.tokenId)
        }
    }

    return (
        type === 'Mask' ?
            <Box sx={sx.simpleHolder}>
                <Typography sx={sx.title} variant='pageTitleDescription'>{title}</Typography>
                {metadatas.length > 0 ?
                    <Box sx={sx.root} className='nft-list'>
                        {metadatas.map((item, index) => {
                            if (item.attributes[0].trait_type === 'Mask') {
                                return (
                                    <Box key={index} sx={{ ...sx.imgHolder, ...(selectedId === item.tokenId && sx.selected) }} onClick={() => { handleClick(metadatas[index], type) }}>
                                        <img src={metadatas[index].image} alt='meta' />
                                    </Box>
                                );
                            }
                        })} </Box>
                    : <Typography variant='pageTitleDescription' sx={sx.sorryText}>
                        unfortunately you do not have any nfts to burn! Buy more on <span onClick={handleOpenOpensea}> opensea. </span></Typography>
                }
            </Box> :
            <Box sx={sx.simpleHolder}>
                <Typography sx={sx.title} variant='pageTitleDescription'>{title}</Typography>
                {metadatas.length > 0 ?
                    <Box sx={sx.root} className='nft-list'>
                        {metadatas.map((item, index) => {
                            if (item.attributes[0].trait_type === 'World') {
                                return (
                                    <Box key={index} sx={{ ...sx.imgHolder, ...(selectedId === item.tokenId && sx.selected) }} onClick={() => { handleClick(metadatas[index], type) }}>
                                        <img src={metadatas[index].image} alt='meta' />
                                    </Box>
                                );
                            }
                        })}
                    </Box>
                    : <Typography variant='pageTitleDescription' sx={sx.sorryText}>
                       Unfortunately you do not own any worlds. Burn Masks to claim worlds and get access to the first level of the game.</Typography>
                }
            </Box>
    )
};

export default NtfList;
