import Burn from "../Views/Burn/Burn";

const routes = [
  {
    path: "/",
    component: <Burn/>,
    exact: true,
  }
];

export default routes;
