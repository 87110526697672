import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import LoadingSpinner from '../../../components/LoadingSpinner';

const BP1 = '@media (max-width: 450px)';
const BP2 = '@media (max-width: 1100px)';
const sx = {
    root: {
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        [BP2]:{
            order: '1'
        }
    },
    imgHolder: {
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            width: '379px',
            height: '379px',

            [BP1]: {
                width: '250px',
                height: '250px',
            },
        },
    },
    loading: {
        position: 'absolute',
        top: '30%',
        left: '0',
        right: '0',
        bottom: '0',
        margin: 'auto',
    },
    qrCodeBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
    },
    linkHolders: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
    },
    opacityColor: {
        color: '#ffffff99',
    },
    qrHolder: {
        width: '130px',
        height: '130px',
        backgroundColor: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    responseLinkHolder: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    }
};
const SuccesBurn = ({ metadata }) => {
    const [loaded, setLoaded] = useState(false);
    const handlePlay = (uniqueGameUrl) => {
        window.open(`${uniqueGameUrl}`, '_blank');
    };
    const share = () => {
        window.open(
            'https://twitter.com/intent/tweet?text=I%27ve%20just%20ascended%20my%20Betwixt%20Brave%20to%20play%20the%20game.%20Ascend%20yours%20braves.betwixt.life%20No%20mask%20to%20ascend?%20Buy%20a%20mask%20on%20OS%20https://opensea.io/collection/betwixt-braves%20@BetwixtNFT',
            '_blank'
        );
    };

    const handleOpenseaNavgiation = () => {
        window.open(
            'https://opensea.io/collection/betwixt-braves?search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[stringTraits][0][name]=Version&search[stringTraits][0][values][0]=Masked&search[toggles][0]=BUY_NOW',
            '_blank'
        );
    };
    return (
        <Box sx={sx.root}>
            <Box sx={sx.imgHolder}>
                <img
                    src={metadata.image}
                    alt='world'
                    style={{ opacity: loaded ? '1' : '0' }}
                    onLoad={() => {
                        setLoaded(true);
                    }}
                />
                {!loaded && (
                    <Box sx={sx.loading}>
                        <LoadingSpinner />
                    </Box>
                )}
            </Box>
            {/* <Button variant='grayButton' onClick={() => handlePlay(metadata)}>
                Play the game
            </Button> */}
            <Box sx={sx.responseLinkHolder}>
                <Typography sx={sx.opacityColor} variant='pageTitleDescription'>
                to play the game, open this link on your phone!
                </Typography>
                <Typography variant='share' style={{textTransform: 'lowercase'}} onClick={() => handlePlay(metadata.uniqueGameUrl)} >{metadata.uniqueGameUrl}</Typography>
            </Box>
            <Box sx={sx.qrCodeBox}>
                <Typography sx={sx.opacityColor} variant='pageTitleDescription'>
                    IF you are on desktop, scan this code
                </Typography>
                <Box sx={sx.qrHolder}>
                    <QRCode value={metadata.uniqueGameUrl} size={120} />
                </Box>
            </Box>
            <Box sx={sx.linkHolders}>
                <Typography variant='share' onClick={share}>
                    SHARE ON TWITTER
                </Typography>
                <Typography
                    sx={sx.opacityColor}
                    variant='share'
                    onClick={handleOpenseaNavgiation}
                >
                    BUY MORE MASKS ON OPENSEA
                </Typography>
            </Box>
        </Box>
    );
};

export default SuccesBurn;
