import { Box } from '@mui/material'
import React from 'react'
import NtfList from './NtfList'
import SuccesBurn from './SuccesBurn'
const BP1 = '@media (max-width: 1100px)';
const sx = {
    contentHolder: {
        width: '90vw',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '25px',
        [BP1]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    button: {
        mt: '64px'
    }
}

const FinnishTab = ({ maskedMetas, worldMetas, handleSelection , metadata, startNewSelection}) => {
    console.log(metadata, 'egyel beljebb')
    return (
        <Box sx={sx.contentHolder}>
            <NtfList metadatas={maskedMetas} type="Mask" title={"Mask"} startNewSelection={startNewSelection}/>
            <SuccesBurn metadata={metadata}/>
            <NtfList metadatas={worldMetas} type="World" title={"World"} startNewSelection={startNewSelection}/>
        </Box>

    )
}

export default FinnishTab