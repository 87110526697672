import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const BP1 = '@media (max-width: 450px)';
const BP2 = '@media (max-width: 1100px)';
const sx = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        [BP2]: {
            order: '2',
        }
    },
    imgHolder: {
        '& img': {
            width: '379px',
            height: '379px',
            [BP1]: {
                width: '250px',
                height: '250px',
            }
        }
    },
    placeholder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        width: '377px',
        height: '377px',
        border: '1px solid #628BA0',
        [BP1]: {
            width: '250px',
            height: '250px',
        }
    }
}
const SelectedImageHolder = ({ selectedNft, handleBurn, buttonText }) => {
    const handleSubmit = () => {
        handleBurn(selectedNft)
    }

    return (
        <Box sx={sx.root}>
            {selectedNft ?
                <Box sx={sx.imgHolder}>
                    <img src={selectedNft.image} alt="" />
                </Box> :
                <Box sx={sx.placeholder}>
                    <Typography variant='boxText'>
                        SELECT NFT
                    </Typography>
                </Box>
            }

            <Button variant='grayButton' disabled={selectedNft ? false : true} onClick={handleSubmit}>
                {buttonText}
            </Button>
        </Box>
    )
}

export default SelectedImageHolder