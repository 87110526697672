import axios from "axios";
import config from "./config/config";

export const updateToken = async (body) => {
    return new Promise((resolve, reject) => {
        axios.put(`${config.API_URL}/metadata/update`, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch(e => {
                reject(e);
            })
            ;
    });
};
export const gameUrl  = async ( body) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.API_URL}/metadata/game-url`, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch(e => {
                reject(e);
            })
            ;
    });
};